// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import railsUJS from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import '@jswork/next';
import '@jswork/next-to-clipboard';
import 'lazyload';

railsUJS.start();
Turbolinks.start();
ActiveStorage.start();

class App {
  detail () {
    $(document).ready(() => {
      // downlad & paste
      $('.is-download').on('click', function(item) {
        const el = $(this);
        const { key } = el.data();
        if (key) {
          nx.toClipboard(key);
        }
      });

      //collapsible catalogue
      $('.docify-catalogue .is-action').on('click', () => {
        $('.docify-catalogue .collapse-content').css({
          'max-height': '10000px'
        });
        $('.docify-catalogue .is-action').hide();
      });
    });
  }

  index () {
    $(document).ready(() => {
      // lazyload images:
      // $('.post-item img').lazyload()

      // https://www.cssscript.com/demo/tilted-navigation-clip-path/
      const toggler = document.querySelector('.menu__toggler');
      const menu = document.querySelector('.menu');

      if (!toggler || !menu) return;

      toggler.addEventListener('click', () => {
        toggler.classList.toggle('active');
        menu.classList.toggle('active');
        $('.menu__overlay').toggleClass('active');
      });

      $('.menu__overlay').on('click', () => {
        toggler.classList.toggle('active');
        menu.classList.toggle('active');
        $('.menu__overlay').toggleClass('active');
      });

    });
  }

  flash () {
    $(document).ready(() => {
      const el = $('.docify-flash');
      el.on('click', () => {
        $('.docify-flash').fadeOut();
      });
      el.delay(2500).slideUp(500);
    });
  }

  collect () {
    const el = $('.docify-collect');
    if (!el.length) return;
    el.find('a').click(() => {
      const collected = el.attr('data-collected');
      const target = collected !== 'true';
      el.attr('data-collected', target);
    });
  }

  animateSlide () {
    if (!$.throttle) return;
    $(document).ready(() => {
      const subtitle = $('.docify-global-search h3');
      $(window).scroll($.throttle(500, true, function() {
        const top = $(window).scrollTop();
        const visible = subtitle.is(':visible');
        if (top > 300) {
          subtitle.slideUp();
        } else {
          subtitle.slideDown();
        }
      }, true));
    });
  }

  start () {
    this.detail();
    this.index();
    this.flash();
    this.animateSlide();
    this.collect();
  }
}

document.addEventListener('turbolinks:load', function() {
  const app = new App();
  app.start();
});
